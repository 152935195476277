import { useAuth0 } from "@auth0/auth0-vue";
import maybeBypassAuthMiddlewareRoutes from "~/utils/maybeBypassAuthMiddlewareRoutes";

export default defineNuxtRouteMiddleware(async (to, from) => {
    if (process.client) {
      const auth0 = useAuth0();
      await auth0.checkSession();

      if (!auth0.isAuthenticated.value && !maybeBypassAuthMiddlewareRoutes(to)) {
        await auth0.loginWithRedirect({
          appState: {
            target: to.path,
            redirect_uri: to.path,
          },
        });
      }
    }
});
